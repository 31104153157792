import React, {
    $tools,
    antdUI,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import "./csgo.scss";
import { csgo } from "@/data/classification.js";
import { CsgoFilter } from "@/components/itemFilter";

const iconData = {
    CSGO_Type_Knife: require("@/images/tradingMarket/csgo1/knife.png"),
    CSGO_Type_Pistol: require("@/images/tradingMarket/csgo1/pistol.png"),
    CSGO_Type_Rifle: require("@/images/tradingMarket/csgo1/rifle.png"),
    CSGO_Type_SMG: require("@/images/tradingMarket/csgo1/smg.png"),
    CSGO_Type_Shotgun: require("@/images/tradingMarket/csgo1/shotgun.png"),
    CSGO_Type_Machinegun: require("@/images/tradingMarket/csgo1/machinegun.png"),
    Type_Hands: require("@/images/tradingMarket/csgo1/hands.png"),
    CSGO_Tool_Sticker: require("@/images/tradingMarket/csgo1/sticker.png"),
    Type_CustomPlayer: require("@/images/tradingMarket/csgo1/customPlayer.png"),
    other: require("@/images/tradingMarket/csgo1/other.png"),
};


export default function Csgo(props) {
    let rememberFilterData = JSON.parse(localStorage.getItem('rememberFilter'));
    const { type: rType, weapon: rWeapon, priceSort: rPriceSort } = rememberFilterData || {};
    const {
        filterChange,
        setCurrentPage,
        cRef,
        setContentType,
        contentType,
        inputMaxPrice,
        inputMinPrice,
        setInputMaxPrice,
        setInputMinPrice
    } = props;
    const [ isActive, setIsActive ] = useState(rType),
        [ type, setType ] = useState(rType),
        [ weapon, setWeapon ] = useState(rWeapon || ""),
        [ priceSort, setPriceSort ] = useState(rPriceSort || 0), //0  无排序 1 升序，2 降序
        [ minPrice, setMinPrice ] = useState(),
        [ maxPrice, setMaxPrice ] = useState();
    
    const childRef = useRef();
    
    const { debounce } = $tools
    
    const sortSwitch = () => {
        // console.log(priceSort)
        if (priceSort === 1) {
            setPriceSort(2);
        } else if (priceSort === 2) {
            setPriceSort(0);
        } else {
            setPriceSort(1)
        }
    };
    
    const getArrow = () => {
        switch (priceSort) {
            case 2:
                return <i className="iconfont icon-cs-jt-xs asc"></i>;
            case 1:
                return <i className="iconfont icon-cs-jt-xs des"></i>;
            default:
                return <i className="iconfont icon-cs-jt-xs des"></i>;
        }
    };
    
    
    // eslint-disable-next-line
    const chooseType = (index, value) => {
        setWeapon("");
        if (value === type) {
            setType('');
            setIsActive('');
            return;
        }
        setIsActive(value);
        setType(value);
    };
    
    const chooseWeapon = (itemIndex, itemValue, weaValue) => {
        setIsActive(itemValue);
        setType(itemValue);
        if (weaValue === weapon) {
            setWeapon('');
            return;
        }
        setWeapon(weaValue);
    };
    
    const handleChangeFilterItem = ({ ev, index, i_val }) => {
        let e = ev || window.event,
            targCls = e.target.className;
        
        switch (targCls) {
            case "csgo_filter_item":
                chooseType(index, i_val);
                break;
            case "icon":
                chooseType(index, i_val);
                break;
            case "title":
                chooseType(index, i_val);
                break;
            default:
                break;
        }
    };
    
    const { checkInputValNumberAndPoint } = $tools;
    
    useImperativeHandle(cRef, () => ({
        getData: childRef.current.getData,
    }));
    
    const priceRangeFilter = (min, max) => {
        setCurrentPage(1);
        setInputMaxPrice(max);
        setInputMinPrice(min);
        setMinPrice(min);
        setMaxPrice(max);
    };
    
    const delayPriceFilter = useRef(debounce(priceRangeFilter, 1000, true)).current
    return (
        <>
            <div className="csgo-item-type">
                { csgo[0].children.map((item, index) => {
                    let ulRight =
                        index === 7 ? "sticker right" : [ 8, 9 ].includes(index) ? "right" : "";
                    let itemCsl =
                        isActive === item.value
                            ? "csgo_filter_item current"
                            : "csgo_filter_item";
                    return (
                        <div
                            className={ itemCsl }
                            key={ index }
                            onClick={ (ev) => {
                                handleChangeFilterItem({ ev, index, i_val: item.value });
                            } }
                            value={ item.value }
                            style={ {
                                marginLeft: index !== 0 && "1.25%",
                            } }
                        >
                            <img
                                className="icon"
                                src={ iconData[item.value] }
                                alt={ item.value }
                            />
                            <p className="title">{ item.label }</p>
                            <ul className={ ulRight }>
                                { item.children.map((el, i) => {
                                    let itemTypeCls =
                                        el.value === weapon ? "item-type active" : "item-type";
                                    return (
                                        <li
                                            className={ itemTypeCls }
                                            value={ el.value }
                                            onClick={ () => chooseWeapon(index, item.value, el.value) }
                                            key={ i }
                                        >
                                            { el.label }
                                        </li>
                                    );
                                }) }
                            </ul>
                        </div>
                    );
                }) }
            </div>
            
            <div className="csgo-clsif-container">
                <CsgoFilter
                    cRef={ childRef }
                    type="tradingmarket"
                    fiType={ type }
                    weapon={ weapon }
                    priceSort={ priceSort }
                    minPrice={ minPrice }
                    maxPrice={ maxPrice }
                    contentType={ contentType }
                    filterChange={ filterChange }
                    useType={ 'market' }
                />
            </div>
            <div className="trading-market-heads">
                <div className="heads-btns">
                    <div
                        onClick={ () => setContentType("onSale") }
                        className={ contentType === "onSale" ? "activeBtn" : "normalBtn" }
                    >
                        出售中
                    </div>
                    
                    
                    {
                        // <div
                        //     onClick={ () => setContentType("buying") }
                        //     className={ contentType === "buying" ? "activeBtn" : "normalBtn" }
                        // >
                        //     求购中
                        // </div>
                    }
                
                </div>
                <div className="heads-filter">
                    <span className="filter-sort" style={ { color: priceSort === 0 && 'gray' } }
                          onClick={ () => sortSwitch() }>
                      按价格
                        { getArrow() }
                    </span>
                    
                    <div className="filter-interval">
                        <div className="interval-input-group">
                            <antdUI.Input
                                allowClear
                                onChange={ (_) =>
                                    setInputMinPrice(checkInputValNumberAndPoint(_.target.value))
                                }
                                value={ inputMinPrice }
                                size="small"
                                placeholder={ '最低价' }
                                style={ { width: "140px", borderRadius: "5px", zIndex: 2 } }
                                prefix="￥"
                            />
                            <span style={ { margin: '0 3px' } }>-</span>
                            <antdUI.Input
                                allowClear
                                size="small"
                                onChange={ (_) =>
                                    setInputMaxPrice(checkInputValNumberAndPoint(_.target.value))
                                }
                                placeholder={ '最高价' }
                                value={ inputMaxPrice }
                                style={ { width: "140px", borderRadius: "5px", zIndex: 2 } }
                                prefix="￥"
                            />
                            
                            <div className="focus-panel">
                                <button onClick={ () => delayPriceFilter('', '') }>重置</button>
                                <button onClick={ () => delayPriceFilter(inputMinPrice, inputMaxPrice) }>筛选</button>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </>
    );
}

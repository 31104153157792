import React, {
    $csgoWearColor,
    // $csgoWearColor1,
    $tools
} from "react";
import { Link } from "react-router-dom";
import BlurText from "@/components/ReactBits/BlurText";
import GradientText from '@/components/ReactBits/GradientText'
import Magnet from '@/components/ReactBits/Magnet'

function CsgoItems(props) {
    let { style: csgo_st, type, item: v, contentType } = props,
        img_bs = "https://steamcommunity-a.akamaihd.net/economy/image/";
    
    const { obj2strUrl } = $tools
    const { color: exterior_bcc, name: exteriorLabel } = $csgoWearColor(v?.exterior)
    // const GTColor = $csgoWearColor1(v?.exterior)?.color
    
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2;
        } else if (quality === 'tournament') {
            return 3;
        } else {
            return 1;
        }
    }
    
    const getConcentration = () => {
        let data = JSON.parse(localStorage.getItem('rememberFilter'));
        let obj = { tab: contentType === 'onSale' ? 1 : 2 };
        window.location.pathname === '/home' && (obj.tab = 1);
        if (data) obj.concentration = data?.concentration?.[0] || 'unlimited';
        return obj2strUrl(obj, '#');
    }
    
    return (
        <Link
            to={
                {
                    pathname: '/marketPlace/accessoriesList',
                    search: obj2strUrl({
                        id: v.id,
                        curGame: '730',
                        curType: getPageType(v.quality),
                    }, '?'),
                    hash: getConcentration()
                }
            }
            
            style={ csgo_st }
            className="csgo-items"
        >
            
            <div className="_img" style={ { paddingBottom: type === 'home' && '1rem' } }
                 onLoad={ (e) => (e.currentTarget.style.opacity = "1") }>
                
                { exterior_bcc && (
                    <div
                        style={ { backgroundColor: exterior_bcc } }
                        className="subscript"
                    
                    >
                        { exteriorLabel }
                    </div>
                ) }
                
                
                <Magnet padding={ 50 } disabled={ false } magnetStrength={ 2 }>
                    <img
                        className="item-img"
                        src={ img_bs + v.icon_url }
                        alt={ v.market_name }
                    />
                </Magnet>
            </div>
            
            
            <div title={ v.market_name } className="item-name" style={ { overflow: 'visible' } }>
                <Magnet padding={ 50 } disabled={ false } magnetStrength={ 2 }>
                    <GradientText
                        // colors={ GTColor }
                        animationSpeed={ 3 }
                        showBorder={ false }
                        className="custom-class"
                    >
                        { v.market_name }
                    </GradientText>
                </Magnet>
                {/*<BlurText text={ v.market_name } delay={ 50 } direction={ Math.random() < 0.5 ? 'top' : 'bottom' }/>*/ }
            </div>
            
            <Magnet padding={ 50 } disabled={ false } magnetStrength={ 2 }>
                <div className="price">
                    { v.price ?
                        <div className={ "flex" } style={ { alignItems: 'end' } }><small>￥</small><BlurText
                            text={ v.price }
                            direction='top'/>
                        </div> : '' }
                    {/*<small>*/ }
                    {/*  {String(v.price).indexOf(".") > 0 &&*/ }
                    {/*    String(v.price).slice(String(v.price).indexOf("."))}*/ }
                    {/*</small>*/ }
                </div>
            </Magnet>
            
            <div className="end-time">
                <Magnet padding={ 50 } disabled={ false } magnetStrength={ 2 }>
                    <p style={ { fontWeight: 700 } }>{ v.saleCount } 件{ contentType === 'buying' ? '需求' : '在售' }</p>
                </Magnet>
            </div>
        
        
        </Link>
    );
}

export default CsgoItems;

import React, { $login } from "react";
import AccessoriesList from "@/pages/AccessoriesList";
import TradingMarkets from "./trandingMarket";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

(async () => {
    const mobi_uid = window?.opener?.localStorage?.mobi_uid
    const pathname = window.location.pathname
    if (mobi_uid || mobi_uid === '') {
        let token, locSearch = window.location.search;
        if (locSearch.match('authorization')) {
            locSearch.replace(/\?authorization=(.*?);/, (_, key) => { token = key })
            window.localStorage.setItem('token', unescape(token))
            window.opener.location.reload();
        }
        if (locSearch.match('bindst')) {
            // window.opener.location.href = window.opener.location.pathname
            window.opener.location.href = "/profile/userConfig"
            window.localStorage.setItem('bindSteam', 1)
        }
        if (! pathname.match('ContactUs') && ! pathname.match('marketPlace/accessoriesList')) window.close()
    }
})()

const marketRouterData = [
    {
        title: "饰品交易市场",
        path: "/",
        component: TradingMarkets,
    },
    {
        title: "饰品交易市场",
        path: "/marketPlace",
        component: TradingMarkets,
    },
    {
        title: "饰品交易市场详情",
        path: "/marketPlace/accessoriesList",
        component: AccessoriesList,
    },
];

function RouteWithSubRoutes(route) {
    if (window.location.href.match('home#login')) {
        setTimeout($login)
        window.history.pushState('', '', '/home')
    }
    return (
        <Route
            path={ route.path }
            render={ (props) => <route.component { ...props } routes={ route.routes }/> }
        />
    );
}

export default function TradingMarket() {
    return (
        // <Router>
        <Switch>
            { false && Router }
            { marketRouterData.map((route, i) => (
                <RouteWithSubRoutes key={ i } { ...route } />
            )) }
        </Switch>
        // </Router>
    );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { antdUI, tools } from '@/utils/index.js';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import App from './App';
import '@/assets/styles/resets.css'
import '@/assets/font/iconfont.css'
import '@/assets/styles/quill.core.css'
import '@/assets/styles/quill.bubble.css'
import '@/assets/styles/quill.snow.css'
import $api from '@/api'
import copyClipboard from 'copy-to-clipboard';

const isAndroid = /Android/i.test(navigator?.userAgent);
if (isAndroid) {
    // const promotedCode = window.location.href.match(/#promotedCode=(\S+)/)?.[1]?.slice(0, 6);
    (function () {
        // const ua = navigator.userAgent.toLowerCase();
        // const config = {
        //     /*scheme:必须*/
        //     scheme_IOS: 'mobi://',
        //     scheme_Adr: `mobi://open_mobi/main?promotedCode=${ promotedCode }`,
        //     download_url: "https://static.mobi2077.com/apk/mobi%2832_64%29.apk",
        //     timeout: 5000
        // };
        
        // function openClient() {
        //     const startTime = Date.now();
        //     const ifr = document.createElement('iframe');
        //     ifr.src = ua.indexOf('os') > 0 ? config.scheme_IOS : config.scheme_Adr;
        //     ifr.style.display = 'none';
        //     document.body.appendChild(ifr);
        //     const t = setTimeout(function () {
        //         const endTime = Date.now();
        //         if (! startTime || endTime - startTime < config.timeout + 200) {
        //             window.location = config.download_url;
        //             copyClipboard(`promotedCode=${ promotedCode }`)
        //         } else {
        //
        //         }
        //     }, config.timeout);
        //     window.onblur = function () {
        //         clearTimeout(t);
        //     }
        // }
        
        window.addEventListener("DOMContentLoaded", function () {
            // document.getElementById("J-open-app").addEventListener('click', openClient, false);
        }, false);
    })()
}

React.antdUI = antdUI;
React.$copy = (text, noTips) => {
    copyClipboard(text)
    console.log(text)
    ! noTips && antdUI.message.success('复制成功')
}
React.$tools = tools;
React.$api = $api;
React.StaticBaseUrl = "https://static.mobi2077.com";
React.$login = function () {
    const oDom = document.querySelector('.login')
    oDom.click();
}
React.$wearData1 = [
    { title: '崭新出厂', ratio: 7, _bcc: 'green' },
    { title: '略有磨损', ratio: 8, _bcc: '#5cb85c' },
    { title: '久经沙场', ratio: 23, _bcc: '#f0ad4e' },
    { title: '破损不堪', ratio: 7, _bcc: '#d9534f' },
    { title: '战痕累累', ratio: 55, _bcc: '#993A38' }
]
React.$wearData2 = [
    { title: '崭新出厂', ratio: 0.07, _bcc: 'green', wear: 'WearCategory0' },
    { title: '略有磨损', ratio: 0.15, _bcc: '#5cb85c', wear: 'WearCategory1' },
    { title: '久经沙场', ratio: 0.38, _bcc: '#f0ad4e', wear: 'WearCategory2' },
    { title: '破损不堪', ratio: 0.45, _bcc: '#d9534f', wear: 'WearCategory3' },
    { title: '战痕累累', ratio: 1, _bcc: '#993A38', wear: 'WearCategory4' }
]
React.$csgoWearColor = (exterior) => {
    switch (exterior) {
        case "崭新出厂":
            return "green";
        case "略有磨损":
            return "#5cb85c";
        case "久经沙场":
            return "#f0ad4e";
        case "破损不堪":
            return "#d9534f";
        case "战痕累累":
            return "#993A38";
        case "无涂装":
            return "#F58C3E";
        case "WearCategory0":
            return { color: "green", name: "崭新出厂" };
        case "WearCategory1":
            return { color: "#5cb85c", name: "略有磨损" };
        case "WearCategory2":
            return { color: "#f0ad4e", name: "久经沙场" };
        case "WearCategory3":
            return { color: "#d9534f", name: "破损不堪" };
        case "WearCategory4":
            return { color: "#993A38", name: "战痕累累" };
        case "WearCategoryNA":
            return { color: "#F58C3E", name: "无涂装" };
        default:
            return {}
    }
}
React.$csgoWearColor1 = (exterior) => {
    switch (exterior) {
        case "崭新出厂":
            return [ "#8fd9a8", "#7acb97", "#66bc86", "#52ae75", "#3e9f64" ];
        case "略有磨损":
            return [ "#5cb85c", "#50a050", "#458a45", "#3a743a", "#305e30" ];
        case "久经沙场":
            return [ "#f0ad4e", "#e69a40", "#d98732", "#cc741e", "#bf610a" ];
        case "破损不堪":
            return [ "#d9534f", "#c74444", "#b63638", "#a5282c", "#941a20" ];
        case "战痕累累":
            return [ "#993A38", "#882f2e", "#772524", "#661a1a", "#550f10" ];
        case "无涂装":
            return [ "#F58C3E", "#e68030", "#d57222", "#c46414", "#b35606" ];
        case "WearCategory0":
            return { color: [ "#8fd9a8", "#7acb97", "#66bc86", "#52ae75", "#3e9f64" ], name: "崭新出厂" };
        case "WearCategory1":
            return { color: [ "#5cb85c", "#50a050", "#458a45", "#3a743a", "#305e30" ], name: "略有磨损" };
        case "WearCategory2":
            return { color: [ "#f0ad4e", "#e69a40", "#d98732", "#cc741e", "#bf610a" ], name: "久经沙场" };
        case "WearCategory3":
            return { color: [ "#d9534f", "#c74444", "#b63638", "#a5282c", "#941a20" ], name: "破损不堪" };
        case "WearCategory4":
            return { color: [ "#993A38", "#882f2e", "#772524", "#661a1a", "#550f10" ], name: "战痕累累" };
        case "WearCategoryNA":
            return { color: [ "#F58C3E", "#e68030", "#d57222", "#c46414", "#b35606" ], name: "无涂装" };
        default:
            return {}
    }
}
React.$CodeLogin = function () {
    const oDom = document.querySelector('.code-login')
    oDom.click();
}

React.$toBindSteam = function () {
    const oDom = document.querySelector('#to-bind-steam')
    oDom.click();
}

React.$toReal = function () {
    const oDom = document.querySelector('#to-real')
    oDom.click();
}
React.$bindSteamLink = function () {
    const oDom = document.querySelector('#bind-steam-link')
    oDom.click();
}
React.$bindSteamApiKey = function () {
    const oDom = document.querySelector('#bind-steam-apiKey')
    oDom.click();
}
Element.prototype.getElemDocPosition = function () {
    let parent = this.offsetParent,
        left = this.offsetLeft,
        top = this.offsetTop;
    while (parent) {
        left += parent.offsetLeft;
        top += parent.offsetTop;
        parent = parent.offsetParent;
    }
    return {
        left: left,
        top: top
    }
}

ReactDOM.render(
    <React.Fragment>
        <App/>
    </React.Fragment>,
    document.getElementById('MoBi')
);


import React, { $tools, antdUI } from "react";
import "./index.scss";
import ItemContainer from "./itemContainer/index";
import InfiniteMenu from '@/components/ReactBits/InfiniteMenu'
import CryptoJS from "crypto-js";

function TraMarContainer(props) {
    const { itemData, itemType, currentPage, total, getDataForPage, marketLoading, contentType, pageSize } = props;
    const getPageType = (quality) => {
        if (quality === "strange" || quality === "unusual_strange") {
            return 2;
        } else if (quality === 'tournament') {
            return 3;
        } else {
            return 1;
        }
    }
    
    function sha256(message) {
        return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
    }
    
    const items = itemData.map(item => ({
        image: `https://static.casego.cn/casego_weapons_webp/${ sha256(item.market_hash_name.replace(/StatTrak™\s*|\s*\(.*?\)/g, "")
        .trim()) }.webp`,
        link: `${ window.location.protocol }/marketPlace/accessoriesList?id=${ item.id }&curGame=730&curType=${ getPageType(item.quality) }`,
        title: `¥${ item.price }`,
        description: item.market_name
    }))
    
    const changePage = (currentPage) => {
        getDataForPage(currentPage);
    };
    
    return (
        <div className="trading-market-container">
            <div style={ { marginTop: "17px" } }>
                { itemData.length !== 0 ? (
                    (Math.round(Math.random() * 100) === 1 || $tools.getUrlQueryValue("use") === "176") ?
                        <div style={ { height: '600px', position: 'relative' } }>
                            <InfiniteMenu items={ items }/>
                        </div> :
                        <ItemContainer contentType={ contentType } itemData={ itemData } itemType={ itemType }/>
                ) : (! marketLoading &&
                    <antdUI.Empty
                        description="暂无数据"
                        style={ { width: "100%", margin: "100px auto 0 auto" } }
                    />
                ) }
            </div>
            < antdUI.Spin tip="正在加载 ..."
                          style={ { margin: '150px auto 0 auto', width: '100%' } }
                          size="large" spinning={ marketLoading }/>
            <div className="inventory-pagination">
                { total ? (
                    <antdUI.Pagination
                        onChange={ (curPage) => changePage(curPage) }
                        showSizeChanger={ false }
                        current={ currentPage }
                        defaultCurrent={ 1 }
                        total={ total }
                        pageSize={ pageSize }
                    />
                ) : (
                    ""
                ) }
            </div>
        </div>
    );
}

export default TraMarContainer;

import React, { Component } from 'react';
import Routers from './router';
import { Provider } from 'react-redux'
import store from './store'
import SplashCursor from '@/components/ReactBits/SplashCursor'

class App extends Component {
    render() {
        return <Provider store={ store }>
            <SplashCursor/>
            <Routers/>
        </Provider>
    }
}

export default App;

